import { ChakraProvider } from "@chakra-ui/react"
// import AboutMe from "./components/AboutMe";
// import ClimbingPhotography2 from "./components/ClimbingPhotography2";
import LandingGalery from "./components/LandingGalery";
import Header from "./components/Header";
// import Footer from "./components/Footer"
// import Collaboration from "./components/Collaboration";
// import Others from "./components/Others";
// import ContactMeSection from "./components/ContactMeSection";
import { lazy, Suspense } from 'react';
import loader from './images/loader.gif'
import { Image } from "@chakra-ui/react";

const ClimbingPhotography = lazy(() => import("./components/ClimbingPhotography2"))
const Collaboration = lazy(() => import("./components/Collaboration"))
const Others = lazy(() => import("./components/Others"))
const AboutMe = lazy(() => import("./components/AboutMe"))
const ContactMe = lazy(() => import("./components/ContactMeSection"))
const Footer = lazy(() => import("./components/Footer"))



function App() {
  return (
    <ChakraProvider>
      <main>
        <Header />
        <LandingGalery />
        <Suspense fallback={<Image ml="auto" src={loader}/>}>
          <ClimbingPhotography />
          <Collaboration />
          <Others />
          <AboutMe />
          {/* <ContactMe /> */}
          <Footer />
        </Suspense>
      </main>
    </ChakraProvider>
  );
}

export default App;
